'use strict';

jQuery.noConflict();

jQuery( document ).ready( function( $ ) {

	var siteInit = {

		DOMready: function() {

			if ( $('#retailers__map-container').length > 0 ) {
				siteInit.retailers();
			}

			if ( $('.js-slider--primary').length > 0 ) {
				siteInit.sliderPrimary();
			}

			if ( $('.js-carousel').length > 0 ) {
				siteInit.carousel();
			}

			if ( $('.js-search').length > 0 ) {
				siteInit.search();
			}

			if ( $('.js-carousel--small').length > 0 ) {
				siteInit.carouselSmall();
			}

			if ( $('.js-carousel-big').length > 0 ) {
				siteInit.carouselBig();
			}

			if ( $('.js-slider--secondary').length > 0 ) {
				siteInit.sliderSecondary();
			}

			if ( $('[href$=".jpg"], [href$=".png"], [href$=".gif"]').length > 0 ) {
				siteInit.modal();
			}

			if ( $('.js-navicon').length > 0 ) {
				siteInit.mobileNavigation();
			}

			siteInit.cookie();

		},

		// Slider
		sliderPrimary: function() {

			$(".js-slider--primary").owlCarousel({
				items: 1,
				loop:false,
				nav:false,
				dots:true
			});

		},

		// Slider
		sliderSecondary: function() {

			$(".js-slider--secondary").owlCarousel({
				items: 1,
				loop:true,
				nav:true,
				dots:true
			});

		},



		// Carousel
		carousel: function() {

			$(".js-carousel").owlCarousel({
				margin: 19,
				loop:false,
				nav:true,
				dots:true,
				responsive : {
					0 : {
						items: 1,
						nav:false,
					},

					480 : {
						items: 2,
						nav:false,
					},
					992 : {
						nav:true,
						items: 4,
					}
				}
			});

		},


		// Carousel Small
		carouselSmall: function() {

			$(".js-carousel--small").owlCarousel({
				margin: 10,
				loop:false,
				nav:true,
				dots:true,
				responsive : {
					0 : {
						items: 1,
						nav:false,
					},

					480 : {
						items: 2,
						nav:false,
					},
					992 : {
						nav:true,
						items: 3,
					}
				}
			});

		},


		// Carousel Big
		carouselBig: function() {

			$(".js-carousel-big").owlCarousel({
				margin: 19,
				loop:false,
				dots:false,
				responsive : {
					0 : {
						items: 2,
						nav:false,
					},
					768 : {
						items: 3,
						nav:false,
					},
					992 : {
						nav:true,
						items: 6,
					}
				}
			});

		},

		// Cookie law
		cookie: function () {

			var lang, cookiePage;

			switch ( $('html').attr('lang') ) {
				case "it-IT":
					lang = "it";
					cookiePage = "/privacy.asp";
				break;
				case "en-US":
					lang = "en";
					cookiePage = "/eng/privacy.asp";
			}

			Yacc.configure({
				cookiePageUrl: cookiePage,
				culture: lang,
			});

		},


		// Modal
		modal: function() {

			$('[href$=".jpg"], [href$=".png"], [href$=".gif"]').colorbox({
				transition: 'elastic',
				speed: 400,
				opacity: 0.8,
				slideshow: true,
				slideshowSpeed: 4000,
				itemsnitialWidth: 50,
				initialHeight: 50,
				maxWidth: '90%',
				maxHeight: '90%',
			});

		},

		// Retailers
		retailers: function() {

			$('#retailers__map-container').storeLocator({
				'mapID' : 'retailers__map',
				'locationList' : 'retailers__list-container',
				'formContainer' : 'retailers__form-container',
				'formID' : 'retailers__form',
				'addressID' : 'retailers__input',
				'regionID' : 'bh-sl-region',
				'autoComplete': true,
				'dataType': 'json',
				'fullMapStart': true,
				'storeLimit' : -1,
				'distanceAlert' : -1,
				'dataLocation': '/template/argal/data/locations.json',
				'infowindowTemplatePath' : '/template/argal/data/infowindow-description.html',
				'listTemplatePath' : '/template/argal/data/location-list-description.html'
			});

		},

		// Mobile navigation
		mobileNavigation: function() {

			$('body').on( 'click', '.js-navicon', function( event ) {

				var $el = $(this).add('#navigation');
				$( $el ).toggleClass('is-active');
				$('body').toggleClass('is-active');
				event.preventDefault();

			});

		},

		// Search
		search: function() {

			$('body').on( 'click', '.js-search', function() {

				$('.searchSmallBox').slideToggle(400);

			});

		}

	};

	siteInit.DOMready();

});
